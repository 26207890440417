<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-20 13:55:22
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-23 18:16:13
 * @FilePath: /yiyun_project/src/views/shortVideo/shortVideoList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="btnBox">
      <el-button size="mini" type="primary" @click="$router.push('/shortVideo/addShortVideo')">+ 添加短剧</el-button>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="短剧名称：">
        <el-input size="small" v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="ruleForm.status" size="small" placeholder="请选择">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="上架" :value="1"></el-option>
          <el-option label="下架" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类：">
        <el-select size="small" clearable v-model="ruleForm.cate_id" placeholder="请选择">
          <el-option v-for="item in cateList" :key="item.id" :label="item.catename" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="ruleForm.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" width="80px" label="ID" align="center"></el-table-column>
      <el-table-column width="220px" label="标题" align="center">
        <template v-slot="{ row }">
          <div class="videoInfo">
            <el-image :src="row.image_url" style="width: 50px; height: 50px" :preview-src-list="[row.image_url]"></el-image>
            <div>
              <div class="title">{{ row.title }}</div>
              <div class="tags">
                <el-tag size="small" :key="index" v-for="(tag, index) in row.label">{{ tag }}</el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" width="80px" label="价格" align="center"></el-table-column>
      <el-table-column prop="vip_price" width="80px" label="vip价格" align="center"></el-table-column>
      <el-table-column prop="episode" width="80px" label="总集数" align="center"></el-table-column>
      <el-table-column prop="sales" width="80px" label="销量" align="center"></el-table-column>
      <el-table-column prop="all_show_num" width="80px" label="播放量" align="center"></el-table-column>
      <el-table-column prop="all_like_num" width="80px" label="点赞量" align="center"></el-table-column>
      <el-table-column prop="all_collect_num" width="80px" label="收藏量" align="center"></el-table-column>
      <el-table-column prop="all_change_num" width="80px" label="转发量" align="center"></el-table-column>
      <el-table-column prop="sort" width="80px" label="排序" align="center"></el-table-column>
      <el-table-column label="更新时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
      </el-table-column>
      <el-table-column width="140px" fixed="right" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" v-if="!row.status" @click="upOrDown(row)">上架</el-button>
          <el-button type="text" v-else @click="upOrDown(row)">下架</el-button>
          <el-button type="text" @click="$router.push(`/shortVideo/addShortVideo?edit_id=${row.id}`)">编辑</el-button>
          <el-button type="text" @click="$router.push(`/shortVideo/addShortVideo?copy_id=${row.id}`)">复制</el-button>
          <el-button type="text" @click="deleteData(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="ruleForm.total" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        status: -1,
        title: '',
        cate_id: 0,
      },
      cateList: [],
    };
  },
  created() {
    this.getCateList();
    this.getDataList();
  },
  methods: {
    upOrDown(data) {
      this.$confirm(`此操作将${data.status ? '下架' : '上架'}短剧:${data.title}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.setStatus, { id: data.id, status: data.status ? 0 : 1 });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success(data.status ? '下架成功' : '上架成功');
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    deleteData(data) {
      this.$confirm(`此操作将删除短剧:${data.title}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.shortVideoDelete, { id: data.id });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    updateData(val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getDataList();
    },
    clearSearch() {
      this.ruleForm.page = 1;
      this.ruleForm.status = -1;
      this.ruleForm.title = '';
      this.ruleForm.cate_id = 0;
      this.getDataList(1);
    },
    // 获取分类列表
    getCateList() {
      this.$axios.post(this.$api.shortVideo.all_cate_list).then(res => {
        if (res.code == 0) {
          res.result.unshift({
            id: 0,
            catename: '全部',
          });
          this.cateList = res.result;
        }
      });
    },
    getDataList(style) {
      if (style) this.ruleForm.page = 1;
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows,
      };
      if (this.ruleForm.title) obj.title = this.ruleForm.title;
      if (this.ruleForm.cate_id) obj.cate_id = this.ruleForm.cate_id;
      if (this.ruleForm.status != -1) obj.status = this.ruleForm.status;
      this.$axios.post(this.$api.shortVideo.shortVideoList, obj).then(res => {
        if (res.code == 0) {
          res.result.list.map(i => {
            if (i.label) {
              i.label = i.label.split(',');
            } else {
              i.label = [];
            }
          });
          this.ruleForm.list = res.result.list;
          this.ruleForm.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #ffffff;
  min-height: 100%;
  width: 100%;
  .btnBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .videoInfo {
    display: flex;
    .el-image {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .title {
      width: 150px;
      text-align: left;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
